import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

import WarningIcon from '@material-ui/icons/Warning';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import { blue } from '@material-ui/core/colors';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(props.open);

 useEffect(() => {
  setOpen(props.open);
}, [props.open])

  const handleClickOpen = () => {
    //setOpen(true);
  };

  const handleClose = () => {
    //setOpen(false);
    props.onConfirm();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

        <MenuItem>
          <ListItemIcon>
            <WarningIcon fontSize="large" style={{ color: red[500] }} />
          </ListItemIcon>
          <Typography variant="inherit">{props.title}</Typography>
        </MenuItem>
          
        </DialogTitle>

        {
          Array.isArray(props.content)
          ?
          (
            <List>
            {
              props.content.map((message) => (
                <>
                  <ListItem button>
                    <ListItemText primary={message} secondary="" />
                  </ListItem>
                  <Divider />
                </>
              ))
            }
            </List>
          )
          :
          (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.content}
            </DialogContentText>
          </DialogContent>)
        }





        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}