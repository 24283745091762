import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';

import AlertDialog from './../AlertDialog/AlertDialog';
import Config from './../config/config'
import DB from './../../util/db/db';

// card core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';

import moment from 'moment'

import SubjectForm from './form/subject'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && (
        <Box p={3}>
          {children}
        </Box>
        )}
    </div>
);

}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonAuto(props) {

  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  //data collector
  const [data, setData] = React.useState(props.data);
  const [changedKeys, setChangedKeys] = React.useState([]);

  const [showAlert, setShowAlert] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState("");
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("warning");

  const getValue = (key) =>
  {
    let value = "";
    if(data !== undefined) value = data[key];
    if(value === undefined) value = "";

    return value;
  } 

const showAlertDialog = (title, message) =>
{
  setShowAlert(true);
  setAlertTitle(title);
  setAlertContent(message);
}

const hideAlertDialog = () =>
{
  setShowAlert(false);
}

  const collectData = (table, newdata) =>
  {
    //console.log("collectData", table, newdata);
    if(table === "subject")
    {
      let k = changedKeys.concat(Object.keys(newdata));
      let uniqueArray = [...new Set(k)];

      const d = {...data, ...newdata};

      setData(d);
      setChangedKeys(uniqueArray);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const save = () => {

    //form validation
    let messages = [];
    if(getValue("meno_priezvisko") === "") 
    {
      if(props.ui === "SubjectLegal" || props.ui === "SubjectCase") messages.push("Nie je zadaný názov firmy");
      else if(props.ui === "SubjectEmployees") messages.push("Nie je zadané meno zamestnanca");
      else messages.push("Nie je zadané meno");
    }

    if(messages.length > 0)
    {
      setShowAlert(true);
      setAlertTitle("Chyba");
      setAlertContent(messages);

      return;
    }

    let dbdata = {};
    for(let i = 0; i < changedKeys.length; i++)
    {
      let key = changedKeys[i];
      dbdata[key] = data[key];
    }

    if(changedKeys.length === 0)
    {
      props.handleBack();
    }
    else
    {
      //update
      if(props.data.id !== undefined)
      {

        if(parseInt(props.data.id) === 0)
        {
          alert("id is 0");
          return;
        }

        let db = new DB();
        db.dbUpdate(dbdata, "subject", props.data.id, "Nebolo možné aktualizovať firmu");

        db.exec().then(
          result => {

            //update kontaktne udaje

            props.handleBack(dbdata);
          },
          error => {
            console.log(error);
            showAlertDialog("Chyba", error);
          }
        );

      }
      else
      {
        //insert
        dbdata["user_id"] = Config.user.id;
        dbdata["zdroj"] = Config.subject_zdroj;//enum business manager
        dbdata["archiv"] = false;
        dbdata["typ"] = "PO";
        dbdata["created"] = moment(new Date()).format("YYYY-MM-DD");
        //update["id"] = 10000;

        let db = new DB();
        db.dbStartTransaction();
        db.dbInsert(dbdata, "subject");

        //insert into subject_case
        //ak pridavame spolocnost
        if(props.ui === "SubjectCase")
        {
          //vytvoreny subject priradime do subject_case
          //moje spolocnosti
          let foreign_keys =  {"subject_id": "subject"};

          let dbSubjectCaseData = {};
          dbSubjectCaseData["subject_id"] = "";//dynamic
          dbSubjectCaseData["user_id"] = Config.user.id;
          dbSubjectCaseData["case_id"] = Config.subject.case_id;//
          dbSubjectCaseData["case_subject_role"] = 7;//Config.case_subject_role;//firma z ciselnikov
          dbSubjectCaseData["share"] = 0;
          dbSubjectCaseData["created"] = moment(new Date()).format("YYYY-MM-DD");
          dbSubjectCaseData["archiv"] = false;

          db.dbInsert(dbSubjectCaseData, "subject_case", foreign_keys, "nepodarilo sa vložiť spoločnosť do subject_case");

        }

        //insert into subject_assigned
        //moje prevadzky
        if(props.ui === "SubjectCaseAndAssigned")
        {
          let foreign_keys =  {"subject_assigned_id": "subject"};

          let dbSubjectCaseAssignedData = {};
          dbSubjectCaseAssignedData["subject_id"] = props.parent_id;//ku komu je firma priradena - parent_id
          dbSubjectCaseAssignedData["subject_assigned_id"] = "";//dynamicky vytvorena firma
          dbSubjectCaseAssignedData["role"] = "pobocka";
          dbSubjectCaseAssignedData["user_id"] = Config.user.id;
          dbSubjectCaseAssignedData["date"] = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

          db.dbInsert(dbSubjectCaseAssignedData, "subject_assigned", foreign_keys, "nepodarilo sa vložiť spoločnosť do subject_assigned");
        }

        //ak pridavame pobocku
        //select subject_assigned_id from subject_assigned where `subject_id` = 107 AND role = 'pobocka'

        //ak pridavame zamestananca
        //insert into subject_assigned

        db.dbCommitTransaction();

        db.exec().then(
          result => {

            //update kontaktne udaje
            console.log(result);
            dbdata["id"] = result[0].lastInsertId;

            props.handleBack(dbdata);
          },
          error => {
            showAlertDialog("Chyba", error);
          }
        );
        

      }

    }

  };

return (
  <>
  <Card>
    <CardHeader color="success">
        
            <Tooltip title="návrat na hlavnú stránku" arrow>
                <Button
                onClick = {(event) =>props.handleBack()}
                startIcon={<ArrowBackIcon />}
                >
                {props.status === "EDIT" ? "editácia" : "vytvorenie"}
                </Button>
            </Tooltip>
    
            <Button onClick = {save} style = {{color: 'white', backgroundColor: "green", float: "right"}} variant="outlined" startIcon={<SaveIcon />}>
                Ulož
            </Button>

    </CardHeader>
    <CardBody>
    <div className={classes.root}>
      <AppBar position="static" color="default">

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Základné údaje" {...a11yProps(0)} />
          <Tab label="Kontaktné údaje" {...a11yProps(1)} />
        </Tabs>
        
      </AppBar>
      <TabPanel value={value} index={0}>
        <SubjectForm collectData = {collectData} table = "subject" data = {data}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        kontaktne udaje
      </TabPanel>

    </div>
    </CardBody>
  </Card>

  <AlertDialog
  open = {showAlert}
  title = {alertTitle}
  content = {alertContent}
  onConfirm ={hideAlertDialog}
  />
  </>
)

}