import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListSubheader from '@material-ui/core/ListSubheader';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

import Config from './../config/config'

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  colorIcon: {
    color: '#98A6AD',
  },
  colorPrimary : {
    backgroundColor: 'white',
  },
  title: {
    flexGrow: 1,
    color: '#98A6AD'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }
}));

export default function AppBarMenu(props) {
  const classes = useStyles();
  const [subject_id, setSubjectId] = React.useState(Config.subject.subject_id);
  const [parent_id, setParentId] = React.useState(Config.subject.subject_id);
  const [open, setOpen] = React.useState(false);

//componentDidMount  
/*
useEffect(() => {

}, []);
*/
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectChange = (event) =>
  {
    setParentId(event.target.value);
    setSubjectId(event.target.value);

    props.handleCompanyChanged(event.target.value, event.target.value);
  }

  const handleSubSelectChange = (event) =>
  {
    if(event.target.value === undefined) return;
    setSubjectId(event.target.value);
    props.handleCompanyChanged(parent_id, event.target.value);
  }

  const buildOptions = () =>{

    let obj = Config.companies;

    let options = Object.keys(obj).map(function(key, index) {

      let options = [];
      options.push(
        <MenuItem value={obj[key].subject_id}>{obj[key].meno_priezvisko}</MenuItem>
      )

      return options;
    })

    return (options)
  }

  const buildSubOptions = () =>{

    let obj = Config.companies[parent_id];

    let options = [];
    options.push(<MenuItem value={obj.subject_id}>centrála</MenuItem>);
    options.push(<ListSubheader>Ostatné pobočky</ListSubheader>);

    obj = Config.companies[parent_id]["companies"];
console.log(obj);

    Object.keys(obj).map(function(key, index) {
//console.log(parent_id, key, index);

      options.push(
        <MenuItem value={obj[key].subject_id}>{obj[key].meno_priezvisko}</MenuItem>
      )
      

    })

    //load another data
      //<ListSubheader>Category 1</ListSubheader>

    return options;
  }

//<AppBar position="fixed">
  const matches = useMediaQuery('(min-width:600px)');
  //console.log(matches);
  //const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (

    <div className={classes.root}>
      <AppBar className={classes.colorPrimary} position="static">
        <Toolbar>

        {!props.sidebarOpen
        ?   <IconButton onClick={(event) => props.hideOrShow()} style = {{display: ""}} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon className={classes.colorIcon}/>
            </IconButton>
        : null
        }

          <Typography variant="inherit" style={{ width: '100%' }}>

            <Box display="flex" p={1}>
              <Box p={1} flexGrow={1}>
                <Typography variant="inherit" className={classes.title} align="left" noWrap>

                  <Tooltip title="Daniel Segeš">
                    <IconButton style = {{marginRight: 5}}  edge="end" aria-label="delete">
                        <AccountCircleIcon style={{ color: '#39afd1' }}/>
                    </IconButton>
                  </Tooltip>

                  <span >Daniel Segeš</span>
                </Typography>
              </Box>
              <Box p={1} style = { matches ? null : {display: "none"} }>
                <FormControl variant="outlined">
                    <Select style = {{display: 'flex'}} onChange={handleSelectChange} value = {parent_id} defaultValue={parent_id} labelId="main-select-label" id="grouped-native-select">
                      {buildOptions()}
                    </Select>
                </FormControl>
              </Box>
              <Box p={1} style = { matches ? null : {display: "none"} }>
                <FormControl variant="outlined">
                <Select onChange={handleSubSelectChange} value = {subject_id} defaultValue={subject_id} labelId="sub-select-label" id="grouped-native-select">
                  {buildSubOptions()}
                </Select>
                </FormControl>
              </Box>

              <Box p={1} style = { matches ? {display: "none"} : null }>
                <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick = {handleClickOpen}
                >
                <MoreVertIcon />
                </IconButton>
              </Box>

            </Box>
          
          </Typography>

        </Toolbar>
      </AppBar>

      <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      fullScreen = {true}
      fullWidth={true}
      onClose={handleClose}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              
            </Typography>
            <Button autoFocus color="inherit">
              Vyber firmu
            </Button>
          </Toolbar>
        </AppBar>

        <DialogTitle>Vyberte firmu</DialogTitle>
        <DialogContent>

          <Box p={1} flexGrow={1}>
            <FormControl style = {{width: '100%'}} variant="outlined">
                <Select style = {{display: 'flex'}} onChange={handleSelectChange} value = {parent_id} defaultValue={parent_id} labelId="main-select-label" id="grouped-native-select">
                  {buildOptions()}
                </Select>
            </FormControl>
          </Box>

          <Box p={1} flexGrow={1}>
            <FormControl style = {{width: '100%'}} variant="outlined">
            <Select onChange={handleSubSelectChange} value = {subject_id} defaultValue={subject_id} labelId="sub-select-label" id="grouped-native-select">
              {buildSubOptions()}
            </Select>
            </FormControl>
          </Box>


        </DialogContent>
      </Dialog>

    </div>
  );
}