
export const LogicOperator = {
    UndefOperator: '',
    And: "AND",
    Or: "OR"
}

export const Function = {
    UndefFn: 'UndefFn',
    Min: "Min",
    Max: "Max",
    Avg: "Avg"
}

class SqlQueryBuilder{

    className = "SqlQueryBuilder";
   type = "SELECT";

   queryData = {
    columns: [],
    tables: [],
    wheres: [],
    variables: {}
   };

   select(column, alias, fn)
   {
        /*
        SqlQueryData::Column col;
        col.column = column;
        col.alias = alias;
        col.func = fn;

        queryData.columns.append(col);
        return this;
        */

       if(Array.isArray(column))
       {
        for(let i = 0; i < column.length; i++)
        {
            let c = column[i];

            let col;
            let alias;
            let fn;

            if(typeof c == "string") col = c;
            if(col === undefined) continue;

            this.select(col, alias, fn);
        }

        return;
       }

       const SqlQueryData_Column = {
           column: column,
           alias: alias,
           func: fn
       };

       this.queryData.columns.push(SqlQueryData_Column);

       return this;

   }

   from(table, alias)
   {

    const SqlQueryData_Table = {
        table: table,
        alias: alias
    };

    this.queryData.tables.push(SqlQueryData_Table);

    return this;
   }

   where(cond, op = LogicOperator.And)
   {
        //queryBuilderTmp->where("`date` >= :date1", SqlQueryBuilder::And);
        if(LogicOperator === undefined) op = LogicOperator.And;

        const SqlQueryData_Where = {
            cond: cond,
            operation: op
        };

       this.queryData.wheres.push(SqlQueryData_Where);
   }

   setParameter(key, value)
   {
    this.queryData.variables[key] = value;
   }

   getSql()
   {
        let sql = this.type + " ";
        
        if(this.queryData.tables.length === 0)
        {
            throw "SqlQueryBuilder::queryData.tables.length === 0";
        }

        // blok stlpcov
        if(this.type === "SELECT")
        {
            if(this.queryData.columns.length === 0) sql = sql + "*";
        }

        let params = [];
        for(let i = 0; i < this.queryData.columns.length; i++)
        {
            let col = this.queryData.columns[i];

            let column = col.column;

            params.push("`" + column + "`");
        }

        sql = sql + params.join(',');

        sql = sql + " FROM ";

        params = [];
        for(let i = 0; i < this.queryData.tables.length; i++)
        {
            let table = this.queryData.tables[i];

            params.push("`" + table.table + "`");
        }

        sql = sql + params.join(',');

        //WHERE
        if(this.queryData.wheres.length > 0)
        {

            let size = this.queryData.wheres.length;

            sql = sql + " WHERE ";

            for(let i = 0; i < size; i++)
            {
                let where = this.queryData.wheres[i];
                sql = sql + where.cond;

                if (where.operation !== LogicOperator.UndefOperator)
                {
                    if(i < (size - 1))
                    {
                        if (where.operation === LogicOperator.And)
                        {
                            sql = sql + " AND ";
                        }
                        else if (where.operation === LogicOperator.Or)
                        {
                            sql = sql + " OR ";
                        }
                    }
                }

            }
        }

        return sql;
   }

   serializeToJson()
   {
        return JSON.stringify({
            className: "SqlQueryBuilder",
            type: this.type,
            queryData: this.queryData
        });
   }

   //SqlQueryBuilder* select(const QString &column, const QString &alias = QString(), Function fn = UndefFn);
   //SqlQueryBuilder* from(const QString &table, const QString &alias = QString());
   //SqlQueryBuilder* where(const QString &cond, LogicOperator op = UndefOperator);
   //SqlQueryBuilder* join(const QString &joinWhat, const QString &cond = QString(), JoinType type = LeftJoin);
   //SqlQueryBuilder* orderBy(const QString &name);
   //SqlQueryBuilder* groupBy(const QString &name);
   //SqlQueryBuilder* limit(int count);
   //SqlQueryBuilder* limit(int from, int to);
   //SqlQueryBuilder* ascend(Ascend asc = Ascending);
   //SqlQueryBuilder* setParameters(const QMap<QString, QVariant> &params);
   //void clearParameters();
   //QMap<QString, QVariant> getParameters();
   //SqlQueryBuilder* setParameter(const QString &key, const QVariant &value);
   //SqlQueryBuilder* removeParameter(const QString &key);
   //void clear();
   //void clear(SqlPart);
   //QString getSql(bool showError = true) const;

           /**
        * \brief Query data storage,
        */

        /*
       struct SqlQueryData
       {
           SqlQueryData() = default;
           SqlQueryData(const SqlQueryData& other)
           {
               hasLimit = other.hasLimit;
               asc = other.asc;
               columns = other.columns;
               tables = other.tables;
               wheres = other.wheres;
               joins = other.joins;
               orderByes = other.orderByes;
               groupByes = other.groupByes;
               connectionName = other.connectionName;
               variables = other.variables;
           }

           struct Column { QString column, alias; SqlQueryBuilder::Function func; };
           struct Table { QString table, alias; };
           struct Join { SqlQueryBuilder::JoinType type; QString cond, what; };
           struct Where { QString cond; SqlQueryBuilder::LogicOperator operation; };

           struct Limit { bool range; int from, to, count; } limit;
           bool hasLimit = false;

           SqlQueryBuilder::Ascend asc;

           QList<Column> columns;
           QList<Table> tables;
           QList<Where> wheres;
           QList<Join> joins;
           QStringList orderByes;
           QStringList groupByes;

           QString connectionName = "";

           QMap<QString, QVariant> variables;
       } queryData;
       */

}

export default SqlQueryBuilder;