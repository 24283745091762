import React from 'react';
import { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

//dialog
import TextField from '@material-ui/core/TextField';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import _ from 'lodash';

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Find = (props) => {

    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [findDialogParams, setFindDialogParams] = useState({});
    //const [maxWidth, setMaxWidth] = React.useState(props.maxWidth);
    const [maxWidth, setMaxWidth] = React.useState('md');

    const handleCloseDialog = () => {
        props.onConfirm();
    };

    const handleChangeDialogParams = (event) =>{
        findDialogParams[event.target.name] = event.target.value;
        //console.log(event.target.name, event.target.value);
    }

    const handleFindDialogConfirm = () => {
        if(_.isEmpty(findDialogParams)) props.onConfirm();
        else props.onConfirm(findDialogParams);
    };

    return (
        <Dialog
          fullScreen = {props.fullScreen}
          fullWidth={true}
          maxWidth={maxWidth}
          open={open}
          onClose={handleCloseDialog}
          //style = {{zIndex: 50000}}
          PaperComponent={PaperComponent}
          PaperProps={{
            style: {
                backgroundColor: "#fff",
            },
         }}
          aria-labelledby="draggable-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        {
          props.fullScreen ? <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              
            </Typography>
            <Button autoFocus color="inherit" onClick={handleFindDialogConfirm}>
              Hľadaj
            </Button>
          </Toolbar>
        </AppBar> : null
        }
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Hľadaj</DialogTitle>
        <DialogContent>
          <DialogContentText>
            zadajte parametre
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="meno_priezvisko"
            name="meno_priezvisko"
            label="Meno / obchodný názov"
            type="meno_priezvisko"
            fullWidth
            onChange={handleChangeDialogParams}
            value= {findDialogParams["meno_priezvisko"]}
          />
          <TextField
            autoFocus
            margin="dense"
            id="ico"
            name="ico"
            label="IČO"
            type="ico"
            fullWidth
            onChange={handleChangeDialogParams}
            value= {findDialogParams["ico"]}
          />
        </DialogContent>
        {
          props.fullScreen ? null : <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Zrušiť
          </Button>
          <Button onClick={handleFindDialogConfirm} color="primary">
            Hľadaj
          </Button>
        </DialogActions>
        }

      </Dialog>
      )
}

export default Find;