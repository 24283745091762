import React, { Component } from 'react';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MTreeItem from '@material-ui/lab/TreeItem';

import cloneDeep from 'lodash.clonedeep'
//import SweetAlert from 'sweetalert2-react';

import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import Typography from '@material-ui/core/Typography';

//treeviewicons
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PeopleIcon from '@material-ui/icons/People';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PermContactCalendarSharpIcon from '@material-ui/icons/PermContactCalendarSharp';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { withStyles } from "@material-ui/core/styles";

// Used cpomponent react-treeview     https://www.npmjs.com/package/react-treeview?fbclid=IwAR0Jzp9mogyjBUG4PcmHpq390meUubFvKKP0aaQK1RrDDFVHMawlSOkNDTQ
//import TreeView from 'react-treeview';
//import './react-treeview.css';

const sherlookData = 
  [
    {
      id: '',
      name: "Prehlady",
      expanded: false,
      icon: 'EqualizerIcon',
      children: [ {
                    id: '',
                    name: "Výnosy vs. náklady",
                    type: 'vynosy-naklady',
                    expanded: false,
                    children: []
                  },
                  {
                    id: '',
                    name: "Náklady - kategórie",
                    type: '',
                    expanded: false,
                    children: []
                  },
                  {
                    id: '',
                    name: "Náklady - klienti",
                    type: '',
                    expanded: false,
                    children: []
                  }
                ]
  
    },
    {
      id: '',
      name: "Výnosy",
      expanded: false,
      icon: 'ArrowUpward',
      children: [
                {
                  id: '',
                  name: "všetky",
                  type: '',
                  expanded: false,
                  children: []
                },
                {
                  id: '',
                  name: "z fiškálnej pokladne",
                  type: '',
                  expanded: false,
                  children: []
                },
                {
                  id: '',
                  name: "z vystavených faktúr",
                  type: '',
                  expanded: false,
                  children: []
                },
                {
                  id: '',
                  name: "z dodacích listov",
                  type: '',
                  expanded: false,
                  children: []
                },
                {
                  id: '',
                  name: "z faktúr za dodacie listy",
                  type: '',
                  expanded: false,
                  children: []
                }
              ]
    },
    {
      id: '',
      name: "Náklady",
      expanded: false,
      icon: 'ArrowDownward',
      children: [
                {
                  id: '',
                  name: "všetky",
                  type: '',
                  expanded: false,
                  children: []
                },
                {
                  id: '',
                  name: "fiškálne",
                  type: '',
                  expanded: false,
                  children: []
                },
                {
                  id: '',
                  name: "z prijatých faktúr",
                  type: '',
                  expanded: false,
                  children: []
                },
                {
                  id: '',
                  name: "z dodacích listov",
                  type: '',
                  expanded: false,
                  children: []
                },
                {
                  id: '',
                  name: "z faktúr za dodacie listy",
                  type: '',
                  expanded: false,
                  children: []
                },
                {
                  id: '',
                  name: "mzdové",
                  type: '',
                  expanded: false,
                  children: []
                },
                {
                  id: '',
                  name: "mzdové - voči štátu",
                  type: '',
                  expanded: false,
                  children: []
                }
              ]
    },
    {
      id: '',
      name: "Klienti",
      type: 'SubjectLegal',
      expanded: false,
      icon: 'People',
      children: []
    },
    {
      id: '',
      name: "Zamestnanci",
      expanded: false,
      icon: 'PermContactCalendarSharp',
      children: [
        {
          id: '',
          name: "prehlad",
          type: 'SubjectEmployees',
          expanded: false,
          children: []
        },
        {
          id: '',
          name: "pracovné pozície",
          type: '',
          expanded: false,
          children: []
        }
      ]
    },
    {
      id: '',
      name: "Nastavenia",
      expanded: false,
      icon: 'Settings',
      children: [
        {
          id: '',
          name: "moje spoločnosti",
          type: 'SubjectCase',
          expanded: false,
          children: []
        },
        {
          id: '',
          name: "moje prevádzky",
          type: 'SubjectCaseAndAssigned',
          expanded: false,
          children: []
        },
        {
          id: '',
          name: "moje pokladne",
          type: '',
          expanded: false,
          children: []
        },
        {
          id: '',
          name: "moje kategórie",
          type: '',
          expanded: false,
          children: []
        },
        {
          id: '',
          name: "používatelia",
          type: '',
          expanded: false,
          children: []
        },
        {
          id: '',
          name: "predplatné",
          type: '',
          expanded: false,
          children: []
        }

      ]
    },
    {
      id: '',
      name: "Manuál",
      type: '',
      expanded: false,
      icon: 'Help',
      children: []
    },
    {
      id: '',
      name: "Exit",
      type: 'exit',
      expanded: false,
      icon: 'Exit',
      children: []
    }

  ]

//https://stackoverflow.com/questions/62641151/styling-selected-treeitem-inside-a-treeview

  const TreeItem = withStyles({
    root: {
      '&$selected > $content $label': {
        color: "white",
        backgroundColor: 'rgba(255,255,255,0.7)',
        xbackgroundColor: 'fade(rgba(255,255,255,0.7), 0.9)'
      },
      '&$selected > $content $label:hover, &$selected:focus > $content $label': {
        color: "black",
        backgroundColor: 'rgba(255,255,255,0.7)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'rgba(255,255,255,0.7)',
        },
      },
    },
    /* Pseudo-class applied to the root element when selected. */
    selected: {},
    /* Styles applied to the tree node content. */
    content: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    /* Styles applied to the label element. */
    label: {
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.3)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    }
  })(MTreeItem);

  const useStyles = theme => ({
    
    
  });

// RecursiveTreeView from material-ui lab
class TreeViewBuilder extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.id = 0;


    //https://www.npmjs.com/package/lodash.clonedeep
    //let copiedObject = JSON.parse(JSON.stringify(sherlookData));

    this.state = {
      showControls: props.showControls
    }

    this.state.data = JSON.parse(JSON.stringify(sherlookData));

    let arr = [];
    this.getExpanded(this.state.data, arr);
    this.state.expanded = arr;

    this.state.singleNodeExpanded = false;
    if(props.singleNodeExpanded !== undefined) this.state.singleNodeExpanded = props.singleNodeExpanded

    this.state.showAlert = false;
    this.state.AlertTitle = "";
    this.state.AlertText = "";

    this.selectedId = null;

    this.theme = createMuiTheme({
      typography: {
        root: {
          "&.Mui-selected > .MuiTreeItem-content": {
            color: "red"
          }
        },
        body1: {
          //fontFamily: "'Open Sans', sans-serif",
          //fontWeight: 400,
          fontSize: props.fontSize,
        }
      }
    })
  }

  getUniqueId = () =>{
    this.id++;
    return this.id;
  }


  showControls = () => {
    if(this.state.showControls){
      return <>
              <AddCircleIcon
                onClick = {() => alert('Plus clicked')}
                style = {{color: 'green'}}
                fontSize = {this.props.fontSize > 30 ? 'large' : 'default'}
              />
              <RemoveCircleIcon 
                onClick = {() => alert('Minus clicked')}
                style = {{color: 'red', marginRight: '10px'}}
                fontSize = {this.props.fontSize > 30 ? 'large' : 'default'}
              />
            </>
        
    }
  }

  getExpanded(node, arr)
  {
    for(let i = 0; i < node.length; i++)
    {
      let item = node[i];
      
      //set id of not exist
      if(item.id === undefined || item.id === "" ) node[i].id = this.getUniqueId();

      let expanded = false;
      if(item.expanded !== undefined) expanded = item.expanded;
      if(expanded) arr.push( "" + node[i].id );

      if(item.children.length > 0) this.getExpanded(node[i].children, arr);
    }
  }

  findNode(nodes, index, counter)
  {

    if(counter === undefined) counter = {c: 0};

    let foundNode = null;
    for(let i = 0; i < nodes.length; i++)
    {
      counter.c++;
      //console.log(nodes[i].name, i, index, counter.c);

      if(counter.c === index) 
      {
        return nodes[i];
      }

      if(nodes[i].children.length > 0) 
      {
        foundNode = this.findNode(nodes[i].children, index, counter);
      }
      if(foundNode != null) return foundNode;

      
    }

    return foundNode;
  }

  handleSelect = (event, nodeIds) => {
    //console.log(this.state.data);
    //console.log(nodeIds);

    //let counter = {c: 0};
    let node = this.findNode(this.state.data, parseInt(nodeIds));
    //console.log( node, nodeIds, this.state.data);
    if(node == null) 
    {
      console.log( node, nodeIds, this.state.data);
      return;
    }
    if(node.type === undefined) return;

    let showObject = node.name;
    if(node.type !== '') showObject = node.type;
    this.props.showObject(showObject);

    /*
    if(nodeIds === undefined) return;

    this.selectedId = nodeIds;
    if(this.state.data[this.selectedId] === undefined) return;
    
    console.log(nodeIds, this.state.data[this.selectedId]);

    let type = this.state.data[this.selectedId].type;
    if(type === undefined) return;

    alert(type);
    */
  };

  handleToggle = (event, nodeIds) => {
    
    if(this.state.singleNodeExpanded)
    {
      let firstElement = nodeIds[0];
      nodeIds = [];
      nodeIds.push(firstElement);
    }

    this.setState({expanded: nodeIds});
  };

  insertNode(node, id, type)
  {
    for(let i = 0; i < node.length; i++)
    {
      let item = node[i];
      if(item.id === id)
      {

        let obj = {
          id: '-' + new Date(),
          name: "test" + new Date(),
          expanded: true,
          children: []
        }

        if(type === "before")
        {
          node.splice(i, 0, obj);
        }
        else if(type === "after")
        {
          node.splice(i + 1, 0, obj);
        }
        else if(type === "into")
        {
          item.children.push(obj);
        }

        return;
      }

      if(item.children.length > 0)
      {
        this.insertNode(item.children, id, type);
      }
    }
  }

  handleEditNode = (event) =>
  {

  }

  handleInsertNode = (event) =>
  {
    if(this.selectedId == null)
    {
      this.setState({showAlert: true, AlertTitle: "Varovanie", AlertText: "nie je označený riadok"});

      return;
    }

    let type = "into";//before, after, into
    var data = cloneDeep(this.state.data);
    this.insertNode(data, this.selectedId, type);

    let arr = this.state.expanded;
    //this.getExpanded(data, arr);
    //console.log(arr);

    if(type === "into")
    {
      arr.push(this.selectedId);
    }

    this.setState({
      data: data,
      expanded: arr
    });

  }

  deleteNode(node, id)
  {
    for(let i = 0; i < node.length; i++)
    {
      let item = node[i];
      if(item.id === id)
      {
        console.log(id);

        node = node.splice(i, 1);

        return;
      }

      if(item.children.length > 0)
      {
        this.deleteNode(item.children, id);
      }
    }
  }

  handleDeleteNode = (event) =>
  {
    if(this.selectedId == null)
    {
      this.setState({showAlert: true, AlertTitle: "Varovanie", AlertText: "nie je označený riadok"});
      return;
    }

    var data = cloneDeep(this.state.data);
    this.deleteNode(data, this.selectedId);

    this.setState({data: data});

    this.selectedId = null;

  }

  getIcon(type)
  {
    if(type === "EqualizerIcon") return <EqualizerIcon color="inherit" />;
    else if(type === "ArrowUpward") return <ArrowUpwardIcon color="inherit" />;
    else if(type === "ArrowDownward") return <ArrowDownwardIcon color="inherit" />;
    else if(type === "People") return <PeopleIcon color="inherit" />;
    else if(type === "AccountTree") return <AccountTreeIcon color="inherit" />;
    else if(type === "PermContactCalendarSharp") return <PermContactCalendarSharpIcon color="inherit" />;
    else if(type === "Settings") return <SettingsIcon color="inherit" />;
    else if(type === "Help") return <HelpIcon color="inherit" />;
    else if(type === "Exit") return <ExitToAppIcon color="inherit" />;

    return null
  }

  renderTree = (nodes) => {
    let content = [];
    nodes.map(item => {

      let id = item.id;
      if(id === undefined || id === "") id = this.getUniqueId();
      id = "" + id;

     let label = 
        <div style={{display: 'flex', alignItems: 'center', padding: this.props.padding}}>
          {this.showControls()}
          {this.getIcon(item.icon)}
          <Typography key = {"typography_" + id} variant="body1">
              {item.name}
          </Typography>
        </div>
        
        content.push( 
                    <div key = {"div" + id}>
                      <TreeItem style = {{color: this.props.color, backgroundColor: this.props.backgroundColor}} key={id} nodeId={id} label={label}>
                        {item.children ? this.renderTree(item.children) : null}
                      </TreeItem>
                    </div>
                  );
      }
    )
    return content;
  }

  /*
  renderTree = (nodes) => {
    let content = [];
    nodes.map(item => {
      content.push(
                    <TreeItem key={item.id} nodeId={item.id} label={item.name}>
                      {item.children ? this.renderTree(item.children) : null}
                    </TreeItem>
                  );
      }
    )
    return content;
  }
  */


  render () {

    const { classes } = this.props;

    return (
      <div>
        {
          !this.state.showControls ? null :         <div>
          <button onClick = {this.handleDeleteNode}>-</button>
          <button onClick = {this.handleInsertNode}>+</button>
          <button onClick = {this.handleEditNode}>edit</button>
        </div>
        }
        <ThemeProvider theme={this.theme}>
          <TreeView
            key = {"menu"}
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeSelect={this.handleSelect}
            onNodeToggle={this.handleToggle}
            expanded={this.state.expanded}
            defaultExpanded={[]}
            ref={this.myRef}
          >
            {this.renderTree(this.state.data)}
          </TreeView>
        </ThemeProvider>

      </div>
    );
  }
}

export default withStyles(useStyles)(TreeViewBuilder)
