import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { blue } from '@material-ui/core/colors';

import TableSortLabel from '@material-ui/core/TableSortLabel';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

let sorted = [];

function getMaxHeight(isMobile = true)
{
  let h = window.innerHeight - 250;
  if(!isMobile) h = h - 40;//setDense widget

  if(h < 200) h = 200;
  return h;
}

const useStyles = makeStyles({
  root: {
    width: '100%'    
  },
  container: {
    maxHeight: getMaxHeight(),
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#6bb7fd',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: 'gainsboro',
    },
  },
}))(TableRow);


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [dense, setDense] = React.useState(props.dense);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [lastIndex, setLastIndex] = React.useState(-1);  

  const handleChangeDense = (event) => {
    setDense(event.target.checked);

    if(props.handleChangeDense !== undefined)
    {
      props.handleChangeDense(dense);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const actionStyle={
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "70px",
    display: "block",
    overflow: "hidden"
  }

  const showDataFromColumns = (row) => {

    let result = [];
    if(props.columns.length > 0){
      for (let column of props.columns) {
        if(!props.visibleColumns.includes(column)) continue;

        if(column === "actions")
        {

          result.push(<TableCell style = {{maxWidth: "30px"}} key={row.id + "_" + column}>
            <div style = {actionStyle}>
              <IconButton aria-label="expand row" size="small" onClick={() => handleEditIconClicked(row)}>
                <EditIcon/>
              </IconButton>
              <IconButton aria-label="expand row" size="small" onClick={() => handleDeleteIconClicked(row)}>
                <DeleteIcon/>
              </IconButton>
              </div>
          </TableCell>);
          //          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          //{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          //</IconButton>
        }
        else if(row.hasOwnProperty(column)){
          result.push(<TableCell key={row.id + "_" + column}>
                      {row[column]}
                    </TableCell>);
        } else {
          result.push(<TableCell key={row.id + "_" + column}>
                      {null}
                    </TableCell>);
        }
      }
    }
    return result;
  }
  

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const renameTableColumn = (column) =>{
    if(column in props.renamedColumns) return props.renamedColumns[column];
    return column;
  }

  const isSelected = (_index) => selectedRows.indexOf(_index) !== -1;

  const handleEditIconClicked = (row) => {

    let newSelected = [];
    newSelected.push(row._index);
    setSelectedRows(newSelected);

    props.handleTableClick(newSelected);
    props.handleTableDoubleClick(null, row.id, row._index)
  };

  const handleDeleteIconClicked = (row) => {

    let newSelected = [];
    newSelected.push(row._index);
    setSelectedRows(newSelected);

    props.handleTableClick(newSelected);
    props.handleTableDeleteClick(null, row.id, row._index);
  };

  const handleTableClick = (event, row, index) => {
    
    let newSelected = [];

    //console.log("table - handleClick", row, row._index, index);

    if (event.ctrlKey) {
      const indx = selectedRows.indexOf(row._index);

      newSelected = [...selectedRows];

      if (indx === -1) {
        newSelected.push(row._index);
      }
      else
      {
        newSelected.splice(indx, 1);
      }

      setLastIndex(index);

    }else if (event.shiftKey) {

      if(selectedRows.length === 0) 
      {
        newSelected.push(row._index);
      }

      let fromValue = lastIndex;
      let toValue = index;

      if( fromValue > toValue )
      {
        fromValue = index;
        toValue = lastIndex;
      }

      //console.log(fromValue, toValue);

      newSelected = [...selectedRows];
      for(let indx = fromValue; indx <= toValue; indx++)
      {
        let row = sorted[indx];
        const t = selectedRows.indexOf(row._index);

        if (t === -1) {
          newSelected.push(row._index);
        }
        
      }

    }else {

      const indx = selectedRows.indexOf(row._index);

      if (indx === -1) {
        newSelected.push(row._index);
      }
      else
      {
        selectedRows.splice(indx, 1);
      }

      setLastIndex(index);

    }

    if(newSelected.length === 0) setLastIndex(index);

    setSelectedRows(newSelected);
    props.handleTableClick(newSelected);
      
  }
  
  if(props.data === undefined || props.data === null)
  {
    return <CircularProgress />;
  }

  sorted = stableSort(props.data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
  return (
    <Grid item xs={12}>
    <Paper className={classes.root}>
      <TableContainer className={classes.container} style = {{maxHeight: getMaxHeight(props.isMobile)}}>
        <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'auto' }} size = {dense ? 'small' : 'medium'}>
        <TableHead>
          <TableRow >
          {Object.values(props.columns).map((column, index) => {
            
            //use filter?
            if(!props.visibleColumns.includes(column)) return null;

            if(column === "actions")
            {
              return (
                <StyledTableCell style = {{width: "50px"}} key={column}>
                </StyledTableCell>
              )
            }

            return (
              <StyledTableCell 
                key={column}
                sortDirection={orderBy === column ? order : false}
              >
                <TableSortLabel
                  active = {orderBy === column}
                  direction={orderBy === column ? order : 'asc'}
                  onClick={createSortHandler(column)}
                >
                {renameTableColumn(column)}
                </TableSortLabel>
              </StyledTableCell>
              )
            })
            }
          </TableRow>
        </TableHead>
          <TableBody>
          {sorted.map((row, index) => {
            const isItemSelected = isSelected(row._index);

            let backgroundColor;
            if(isItemSelected){
              backgroundColor = blue[200];
            }

            return (
              <StyledTableRow 
                key={row.id} 
                hover 
                role="checkbox" 
                tabIndex={-1} 
                selected={isItemSelected}
                style={{backgroundColor: backgroundColor, MozUserSelect: "none", WebkitUserSelect: "none", msUserSelect: "none"}}
                onClick={(event) => handleTableClick(event, row, index)} 
                onDoubleClick={(event) => props.handleTableDoubleClick(event, row.id, row._index)}
              >
                {showDataFromColumns(row)}
              </StyledTableRow>
            )
          })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage='Riadkov na stránke:'
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        style={{ display:"flex" }}
      />
    </Paper>

    {
      props.isMobile ? null :  <FormControlLabel style = {{marginLeft: "5px"}}
      control={<Switch checked={dense} onChange={handleChangeDense} />}
      label="kompaktné zobrazenie"
      />
    }

    </Grid>
  );
}
