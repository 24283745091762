import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import  { Redirect } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';

import { withStyles } from "@material-ui/core/styles";

import logo from './../../assets/img/business-manager.svg'
import isLocalhost from '../../util/isLocalhost'
import DB from '../../util/db/db'
import Config from '../config/config'

import axios from 'axios'

const useStyles = theme => ({
    root: {
      backgroundColor: "transparent",
      backgroundImage: "linear-gradient(135deg, #1B8AF1, #d8deeb)"    //backgroundImage: "linear-gradient(to right, #1B8AF1, #d8deeb)"
     },
  });

  const initialState = {
    auth: false,
    showprogress: false,
    company: "",
    urlCompany: "",
    username: "",
    password: "",
    usernameError: "",
    passwordError: "",
    companyError: "",
    loginError: "",
    rememberMe: true,
    locale: 'sk-SK'
  };

class Login extends Component {

    constructor(props) {
      super(props);

      this.state = {
        ...initialState
      }

      if(isLocalhost)
      {
        this.state.username = "admin";
        this.state.password = "admin";
      }
    }

    handleChange = event => {
        this.setState({
          ...this.state,
          [event.target.name]: event.target.value
        });
    };

    validate = () => {
        let usernameError = "";
        let passwordError = "";

        if (!this.state.username){
            usernameError = "Nie je zadané prihlasovacie meno";
        }

        if (!this.state.password){
            passwordError = "Nie je zadané heslo";
        }

        if (usernameError || passwordError) {
            this.setState(
            {
                ...this.state,
                "usernameError": usernameError,
                "passwordError": passwordError
            }
            );
            return false;
        }

        return true;
    }

    login()
    {
      axios('/backend/login.php',
      {
        method: 'POST',
        mode: 'no-cors',
        data:{
          company: "zelenay",
          username: this.state.username,
          password: this.state.password,
          token: this.state.token
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          //'Content-Type': 'application/json',
          "Content-Type": "text/html;charset=UTF-8"
        },
        withCredentials: false,
        credentials: 'same-origin',
      })
      .then(response => {  
  
        if(!response.data.sucess)
        {
          throw response.data.message;
        }
  
        let params = {
          token: response.data.token,
          company: this.state.company,
          user: {...response.data.user},
          subject: {...response.data.subject},
          db_structure: response.data.db_structure,
          app_version: response.data.app_version,
        }
  
        //--------------------------------------------
        Config.user = response.data.user;
        Config.subject = response.data.subject;
        Config.companies = response.data.companies;
        //--------------------------------------------


        if(!this.state.rememberMe) params.token = "";

        if (typeof(Storage) !== "undefined") {
          localStorage.setItem("token", params.token);
          localStorage.setItem("company", params.company);
        } else {
          // Sorry! No Web Storage support..
        }
        
        DB.token = params.token;
        DB.db_structure = params.db_structure;
        DB.company = this.state.company;

        this.setState({auth: true})
        //this.props.loginHandler(params);
      })
      .catch(error => {
        const message = error + "";
  
        this.setState(
          {
            ...this.state,
            showprogress: false,
            auth: false,
            loginError: message
          }
        );
      }); 
  
    }
    
    handleLogin = event => {
        event.preventDefault();
        const isValid = this.validate();
    
        if (isValid) {
    
          this.setState(
            {
              ...this.state,
              showprogress: true,
              loginError: ""
            }
          );
    
          this.login();
          
        }
      };

    render(){

        if(this.state.auth)
        {       
            return <Redirect to={{
              pathname: "/",
              auth: true
            }} />
        }

        const { classes } = this.props;

        if(this.state.showprogress)
        {
          return(
            <div>
            <Dialog open={true}  aria-labelledby="form-dialog-title"
              BackdropProps={{
                classes: {
                  root: classes.root
                }
              }}
            >
              <Box minWidth="90">
                <img src={logo} className="App-logo" alt="logo" />
              </Box>
    
              <DialogContent>
        
                <div>Prihlasujem do systému...</div>
                <br></br>
    
                <CircularProgress />
    
              </DialogContent>
            </Dialog>
          </div>
          )
        }

        return (

            <div>
              <Dialog open={true}  aria-labelledby="form-dialog-title"
                BackdropProps={{
                  classes: {
                    root: classes.root
                  }
                }}
              >
                  <br/><br/>
                <img style = {{marginTop: "10px"}} src={logo} className="App-logo" alt="logo" />
      
                <DialogContent style={{ overflow: "hidden" }}>
      
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        margin="dense"
                        id="username"
                        name="username"
                        label="Prihlasovacie meno"
                        type="text"
                        fullWidth
                        value= {this.state.username}
                        onChange={this.handleChange}
                      />
                      <div style={{ fontSize: 12, color: "red", textAlign: "left"}}>
                        {this.state.usernameError}
                      </div>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          id="password"
                          name="password"
                          label="Heslo"
                          type="password"
                          fullWidth
                          value= {this.state.password}
                          onChange={this.handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={'sk-SK'}
                          
                        >
                          <MenuItem value={'sk-SK'}>Slovensky</MenuItem>
                          <MenuItem value={'en-US'}>English</MenuItem>
                        </Select>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                        control={<Checkbox checked={this.state.rememberMe} onChange={this.handleChangeCheckBox}  name="rememberMe" />}
                        label="zapamätaj si ma"
                        />
                      </Grid>

                  </Grid>


                  <div style={{ fontSize: 12, color: "red", textAlign: "left"}}>
                    {this.state.passwordError}
                  </div>
                  <div style={{ fontSize: 12, color: "red", textAlign: "left"}}>
                    {this.state.loginError}
                  </div>
                  
                </DialogContent>
                <DialogActions>
                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item sm={12} xs={12}>
                        <Button onClick={this.handleLogin} color="primary">
                          Prihlásiť do systému
                        </Button>
                    </Grid>
                  </Grid>
                    
                </DialogActions>
              </Dialog>
            </div>
          );
    }

}

export default withStyles(useStyles)(Login)