import React, { Component } from 'react';
import Sidebar from "react-sidebar";
import logo from './assets/img/icon-logo.png';
import Typography from '@material-ui/core/Typography';
import './App.css';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TreeViewBuilder from './businessmanager/treeviewbuilder/treeviewbuilder';
import bgImage from "assets/img/sidebar-2.jpg";
import Config from './businessmanager/config/config';

import { BrowserRouter }from 'react-router-dom';
import { Route } from 'react-router-dom'

import  { Redirect } from 'react-router-dom'


import AppMenu from './businessmanager/AppMenu/AppMenu';
import isMobile from './util/isMobile';
import Subject from './businessmanager/subject/subject';
import DB from './util/db/db'

import axios from 'axios'

//axios.defaults.baseURL = 'http://localhost:3000/';
axios.defaults.baseURL = 'http://businessmanager.sherlook.sk/';

class App extends Component {

  constructor(props) {
    super(props);

    let host = window.location.host;
    let protocol = window.location.protocol;
    let parts = host.split(".");

    //console.log(parts, window.location);

    let token = localStorage.getItem("token");
    DB.token = token;
    DB.company = "zelenay";
    //DB.db_structure = params.db_structure;

    var m = this.isMobile();
    var open = true;
    var docked = true;

    if(m)
    {
      open = false;
      docked = null;
    }

    this.state = {
      sidebarOpen: open,
      showObject: "welcome",
      shadow: false,
      docked: docked,
      token: token,
      auth: props.location.auth,
      subject_id: Config.subject.subject_id,
      parent_id: Config.subject.subject_id
    };

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);

    this.styles = {
      overlay: {
        zIndex: 999,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        visibility: "hidden",
        transition: "opacity .3s ease-out, visibility .3s ease-out",
        backgroundColor: "rgba(0,0,0,.5)",
        
      },
      sidebar: {
        zIndex: 1000,
        backgroundColor: "rgba(60,60,60, 1)",
         xbackgroundImage: "url(" + bgImage + ") ",
         xbackgroundRepeat: 'no-repeat',
        backgroundBlendMode: "overlay",
        backgroundSize: "cover"
      }
    }
  }


  //no-repeat fixed 50% 0px

  isMobile()
  {
    var width = window.innerWidth;
    //var height = window.innerHeight;
    
    var m = isMobile();

    //for desktop
    if(width < 600) m = true;

    return m;
  }

  updateDimensions(){

    var m = this.isMobile();

    var open = true;
    var docked = true;

    if(m)
    {
      open = false;
      docked = null;
    }

    let s = {
      sidebarOpen: open,
      shadow: false,
      docked: docked,
      isMobile: m
    };

    this.setState(s);
  }

  componentDidMount(){
    window.addEventListener('resize', this.updateDimensions.bind(this));
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  hideOrShow()
  {
    if(this.state.docked !== null) this.onSetSidebarOpen(!this.state.docked);
    else this.onSetSidebarOpen(!this.state.sidebarOpen);
  }

  handleCompanyChanged(parent_id, subject_id)
  {
    let s = {
      parent_id: parent_id,
      subject_id: subject_id
    };

    this.setState(s);
  }

  handleMenuClicked(param)
  {
    var m = this.isMobile();

    let s = {
      sidebarOpen: !m,
      showObject: param
    };

    this.setState(s);
  }

  onSetSidebarOpen(open) {
    if(this.state.docked === null) this.setState({ sidebarOpen: open });
    else this.setState({ sidebarOpen: false, docked: open });
  }

  buildSideBar()
  {
    let d = (
      <div className='container' style={{padding: '10px', width: '300px'}}>

        <div style = {{width: "100%", textAlign: "center"}}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem>
                <img src={logo} height = "18px" alt="logo" />
                <span style={{ color: 'white', marginLeft: 4, fontSize: 20 }}>Business</span>
                <span style={{color: '#39afd1', fontSize: 20}}>Manager</span>

                  <ListItemSecondaryAction>
                    <IconButton onClick={(ev) => this.hideOrShow()} edge="end" aria-label="delete">
                      <ChevronLeftIcon style={{ color: 'white' }}/>
                    </IconButton>
                  </ListItemSecondaryAction>
              </ListItem>
            </List>
        </div>

        <hr style = {{backgroundColor: "white", height: "1px", border: "0px"}}/>

        <TreeViewBuilder singleNodeExpanded = {true} padding = {"10px"} color = {"white"} showObject={(params) => this.handleMenuClicked(params)} showControls={false} fontSize={40}/>
      </div>
    )  

    return d;
  }

  render() {

    if(!this.state.auth)
    {   
        let param = "/login";
        //if(this.state.company !== "") param = "/login?company=" + this.state.company;

        /*
        let loginparams = findGetParameterInUrl("params");
        if(loginparams != null)
        {
          param = "/login?params=" + loginparams;
        }
        */

        return <Redirect to={param}  />
    }

    let mainContent = <div style = {{padding: "20px"}} >Vitajte</div>;
    if(this.state.showObject === "SubjectLegal")
    {
      mainContent = <Subject ui = "SubjectLegal" subject_id = {this.state.subject_id} parent_id = {this.state.parent_id} isMobile = {this.state.isMobile} title = {"Klienti"} typ = {"PO|SZCO"}/>
    }
    else if(this.state.showObject === "SubjectEmployees")
    {
      mainContent = <Subject ui = "SubjectEmployees" subject_id = {this.state.subject_id} parent_id = {this.state.parent_id} isMobile = {this.state.isMobile} title = {"Zamestnanci"} typ = {"FO"}/>
      
    }
    else if(this.state.showObject === "SubjectCase")
    {
      mainContent = <Subject ui = "SubjectCase" subject_id = {this.state.subject_id} parent_id = {this.state.parent_id} isMobile = {this.state.isMobile} title = {"Spoločnosti"} typ = {"PO|SZCO"}/>
    }
    else if(this.state.showObject === "SubjectCaseAndAssigned")
    {
      mainContent = <Subject ui = "SubjectCaseAndAssigned" subject_id = {this.state.subject_id} parent_id = {this.state.parent_id} isMobile = {this.state.isMobile} title = {"Prevádzky"} typ = {"PO|SZCO"}/>
    }
    else if(this.state.showObject === "exit")
    {
      let param = "/login";
      return <Redirect to={param}  />
    }
    else if(this.state.showObject !== "")
    {
      mainContent = <div style = {{padding: "20px"}}>{this.state.showObject}</div>;
    }

    return(

      <Sidebar
        sidebar={this.buildSideBar()}
        docked = {this.state.docked}
        pullRight = {false}
        open={this.state.sidebarOpen}
        shadow = {true}
        styles = {this.styles}
        onSetOpen={this.onSetSidebarOpen}

        xstyles={{ sidebar: { background: "#35907E" } }}
      >

      <AppMenu
        sidebarOpen = {this.state.docked !== null ? this.state.docked : this.state.sidebarOpen}
        handleCompanyChanged = {(parent_id, subject_id) => this.handleCompanyChanged(parent_id, subject_id)}
        hideOrShow = {this.hideOrShow.bind(this)}
      />

        {mainContent}

      </Sidebar>
    )
  };
}

export default App;
