class Config{
    static user = "";
    static subject = "";
    static company = "";
    static subject_zdroj = 9;
    static case_subject_role = 7;//klient
    static object_manager_id = 6//business manager
    static companies = null;
}

export default Config;