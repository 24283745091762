import React, { Component } from 'react';

import DB from '../../util/db/db';
import cloneDeep from 'lodash.clonedeep'
import SqlQueryBuilder, { LogicOperator } from '../../util/db/SqlQueryBuilder';
import AlertDialog from './../AlertDialog/AlertDialog';
import StickyHeadTable from './../table/table';

import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { red } from '@material-ui/core/colors';
import _ from 'lodash';

import isMobile from '../../util/isMobile';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';

// card core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//-----------------------------------------------------


const initialState = {
    data: null,
    columns: [],
    visibleColumns: [],
    usePagination: true,
    index: null,
    deleteConfirmed: false,
    showFindDialog: false,
    showFilter: false,
    showAlert: false,
    alertTitle: "",
    alertType: "warning",
    alertContent: "",
};



class TableDb extends React.Component {

    static TABLE_STATUS = {
        EDIT: 'EDIT',
        ADD: 'ADD',
        SHOWTABLE: 'SHOWTABLE',
    }

    constructor(props) {
        super(props);

        this.state = initialState;
        this.state.status = TableDb.TABLE_STATUS.SHOWTABLE;

        this.db = new DB();
        this.dbTable = "";
        this.selectedRows = [];//list of indexes

        this.showFormInDialog = false;
        this.fullscreen = false;

        this.id = null;
        this.index = null;

        this.anchorMenuRef = React.createRef();

        if(props.isMobile !== undefined) this.setDense(!props.isMobile);
        else this.setDense(!isMobile());
    }

    display(type)
    {
        //to prevent rendering when add/edit is finished
        if(type === "EDIT")
        {
            //if(this.state.rowID > 0) return null;
            //if(this.state.status === "EDIT") return null;
            if(this.state.status === TableDb.TABLE_STATUS.ADD) return null;
            if(this.state.status === TableDb.TABLE_STATUS.EDIT) return null;

            return "none";
        }
    
        if(type === "TABLE")
        {
            //if(this.state.status === "EDIT") return "none";
            if(this.state.status === TableDb.TABLE_STATUS.ADD) return "none";
            if(this.state.status === TableDb.TABLE_STATUS.EDIT) return "none";
            return null;

            //if(this.state.rowID > 0) return "none";
            //return null;
        }
    }

    setDense(dense)
    {
        this.dense = dense;
    }

    setStatus(status)
    {
        this.setState({"status": status});
    }

    getStatus()
    {
        if(this.state.status === TableDb.TABLE_STATUS.EDIT || this.state.status === TableDb.TABLE_STATUS.ADD) return "EDIT";
        else return "SHOWTABLE";
    }

    renderForm()
    {
        return null;
    }

    renderTable()
    {
        //console.log(this.state.data);
        //alert("render table");
        return(
            <StickyHeadTable
            data={this.state.data}
            columns={this.state.columns}
            visibleColumns={this.state.visibleColumns}
            renamedColumns={this.renamedColumns}
            isMobile = {this.state.isMobile}
            pagination = {this.state.usePagination}
            handleTableDoubleClick = {this.handleTableDoubleClick.bind(this)}
            handleTableDeleteClick = {this.handleTableDeleteClick.bind(this)}
            handleBack={this.handleBack}
            handleChangeDense = {this.setDense.bind(this)}
            dense = {this.dense}
            handleTableClick={this.handleTableClick.bind(this)}
            />
        )
    }

    renderUiForm(status)
    {
 
        let form = this.renderForm();

        let txt = "editácia";
        //TODO set vytvorenie

        return (
            <Card>
            <CardHeader color="success">

                <Typography  align="left">
                    <Tooltip title="návrat na hlavnú stránku" arrow>
                        <Button
                        onClick = {event => this.handleBack(event)}
                        startIcon={<ArrowBackIcon />}
                        >
                        {this.title}: {txt}
                        </Button>
                    </Tooltip>
                </Typography>

            </CardHeader>
            <CardBody>
            <div>
                {form}
            </div>
            </CardBody>
        </Card>
        )
    }

    renderUiTable()
    {
        return(
            <Card>
            <CardHeader color="success">
                <Tooltip title="zobraz menu" arrow>
                <Button
                    ref={this.anchorMenuRef}
                    aria-controls={this.state.openMenu ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggleMenu.bind(this)}
                    startIcon={<MoreVertIcon />}
                    >
                    {this.title}
                </Button>
                </Tooltip>
                {this.renderCancelFilterIcon()}
            </CardHeader>
            <CardBody>
            <div>
                {this.renderTable()}
            </div>
            </CardBody>
        </Card>
        )
    }

    renderUi()
    {
        let status = this.getStatus();
        let form = null;

        //add or edit record
        if(status !== TableDb.TABLE_STATUS.SHOWTABLE)
        {
            form = this.renderUiForm();
        }

        return(
            <>
                <div style={{ display: this.display("EDIT") }}>
                    {form}
                </div>

                <div style={{ display: this.display("TABLE") }}>
                    {this.renderUiTable()}
                </div>
            </>
        )
    }

    render() { 

        let findDialog = null;

        if(this.state.showFindDialog)
        {
            findDialog = this.renderFindDialog();
        }

        return (
            <div style={{ width: '100%'}}>
                {this.renderUi()}
                {this.renderMainMenu()}
                {findDialog}
                {this.renderAlertDialog()}
            </div>
        )
    }

    componentDidMount()
    {
        this.showTable();
    }

    renderCancelFilterIcon()
    {
        if (this.state.showFilter) return <Tooltip title="zrušiť filter" arrow><Button size="large" style={{ color: red[500] }} onClick={this.handleCancelFilter.bind(this)} startIcon={<CancelOutlinedIcon />}></Button></Tooltip>;
        return null;
    }

    handleCancelFilter()
    {
        //let newState = {...this.state};
        //newState.showFilter = false;
        //this.setState(newState);
        //this.state.showFilter = false;

        this.setState(prevstate => ({ showFilter: false}));

        this.showTable();
    }

    setRow(row)
    {

    }

    showTable(sqlQueryBuilder)
    {

        if(sqlQueryBuilder === undefined) sqlQueryBuilder = this.getDefaultQueryBuilder();

        //!force to show progress
        this.setState(prevstate => ({ showFilter: prevstate.showFilter, data: null}));

        this.db.dbSelect(sqlQueryBuilder, true).then(
        result => {
            result = this.db.updateJsonTypes(result, this.getTableName(true));
            //console.log(result);

            //modify data
            for(let i = 0; i < result.length; ++i)
            {
                this.setRow(result[i]);
            }

            let newState = {...this.state};
            newState.data = result;
            this.setState(newState);
            //this.showAlertDialog("Info", "Data boli načítané")
        },
        error => {
            //alert(error);
            this.showAlertDialog("Chyba", error);
        }
        );
    }

    getTableName(withView = false)
    {
        return this.dbTable;
    }

    getDefaultQueryBuilder()
    {

        let table = this.getTableName(true);
        if(table === "") new Error("table name or view is not specified");

        let sqlQueryBuilder = new SqlQueryBuilder();
        sqlQueryBuilder.from( table );

        return sqlQueryBuilder;
    }

    buildFindSqlQuery(findDialogParams)
    {
        if(_.isEmpty(findDialogParams)) 
        {
            this.setState(prevstate => ({ showFindDialog: false, showFilter: false}));
            return null;
        }

        this.setState(prevstate => ({ showFindDialog: false, showFilter: true}));

        return this.getDefaultQueryBuilder();
    }

    handleToggleMenu()
    {
        this.setState(prevstate => ({ openMenu: true}));
    }

    //run edit mode
    handleTableDoubleClick(event, id, index){
        console.log("handleTableDoubleClick:", id, index);

        this.id = id;
        this.index = index;
        
        this.setStatus(TableDb.TABLE_STATUS.EDIT);

        //this.setState(prevstate => ({ rowID: id, index: index}));
      }
    
    handleTableClick(selectedRows){
        //console.log("tableDb::handleTableClick", selectedRows);
        this.selectedRows = selectedRows;
    };

    
    handleTableDeleteClick(event, id, index){

        this.id = id;
        this.index = index;

        this.menuClicked("delete");
    }
    
      
      //end of AddRecord/EditRecord
    handleBack(params){

        //TODO refactor it

        //console.log(params, this.selectedRows);

        if(params === undefined) 
        {
            this.setState({"status": TableDb.TABLE_STATUS.SHOWTABLE});
            return;
        }

        let index = this.index;
        //if(this.selectedRows.length === 1) index = this.selectedRows[0];

        if(index === undefined || index === null)
        {
          for(let i = 0; i < this.state.data.length; i++)
          {
            if(this.state.data[i]["id"] === this.id)
            {
              index = i;
              break;
            }
          }
        }

        //TODO clone???
        let newState = this.state;//{...this.state};
        //let newState = {};
        newState.status = TableDb.TABLE_STATUS.SHOWTABLE;
//
        //update current row;
        if(index !== null)
        {
            //newState.data = {...this.state.data[index]};
            for (var prop in params) {
                newState.data[index][prop] = params[prop];
            }
        }
        else
        {
            //console.log("handle back - index is null");

            //TODO fix get last element and it's _index!!!!!
            var last_element = this.state.data[this.state.data.length - 1];
            if(last_element === undefined)
            {
                //WHAT
                alert("tableDb.js fix");
            }

            params["_index"] = last_element._index + 1;
            newState.data.push(params);

            newState.status = TableDb.TABLE_STATUS.SHOWTABLE;
        }
        
        this.setState(newState);
        
    }

    //reimplement it
    renderMenu()
    {
        return null;
    }

    renderMainMenu()
    {
        if(this.anchorMenuRef == null) return null;
        if(this.anchorMenuRef.current == null) return null;
        if(!this.state.openMenu) return null;

        return this.renderMenu();
    }

    renderFindDialog()
    {
        return null;
    }

    renderAlertDialog()
    {
        return (
            <AlertDialog
            open = {this.state.showAlert}
            title = {this.state.alertTitle}
            content = {this.state.alertContent}
            onConfirm ={this.hideAlertDialog.bind(this)}
            />
        )
    }

    hideAlertDialog()
    {        
        this.setState({showAlert: false});
    }

    showAlertDialog(title, message, type)
    {
        if(type === undefined) type = "warning";
        this.setState(prevstate => ({showAlert: true, alertTitle: title, alertContent: message, alertType: type}));
    }

    menuClicked(menu)
    {
        this.menuClosed();

        if(menu === "add")
        {
            this.index = null;
            this.id = null;

            this.setState(prevstate => ({
                openMenu: false,
                index: null,
                status: TableDb.TABLE_STATUS.ADD
            }
            ));


        }
        else if(menu === "find")
        {
            this.setState(prevstate => ({ showFindDialog: true}));
            return true;
        }
        else if(menu === "edit")
        {
            if(this.selectedRows.length === 0)
            {
                this.showAlertDialog("Editácia", "Nie sú označené žiadne riadky");
                return;
            }
            else if(this.selectedRows.length > 1)
            {
                this.showAlertDialog("Editácia", "Označte jeden riadkok");
                return;
            }

            let index = this.selectedRows[0];
            if(index === undefined)
            {
                alert("menuClicked index is undefined");
                return;
            }

            this.id = this.state.data[index].id;
            this.index = index;
            
            this.setStatus(TableDb.TABLE_STATUS.EDIT);
        }
        else if(menu === "delete")
        {
            if(this.selectedRows.length === 0)
            {
                this.showAlertDialog("Mazanie", "Nie sú označené žiadne riadky");
            }

            let data = cloneDeep(this.state.data);
            data.splice(this.index, 1)
            
            //update _index!!!
            for (let i = 0; i < data.length; i++) {
                data[i]._index = i;
            }

            //this.setState(prevstate => ({ data: this.state.data.splice(this.index, 1)}));

            this.setState({data: data});
            //this.showAlertDialog("Mazanie", "pripravujeme...");

            return true;

        }

        return false;
    }

    deleteRow(index)
    {
        let data = cloneDeep(this.state.data);
        data.splice(index, 1)
        
        //update _index!!!
        for (let i = 0; i < data.length; i++) {
            data[i]._index = i;
        }

        this.setState({data: data});
    }

    menuClosed()
    {
        let newState = this.state;
        newState.openMenu = false;
        
        this.setState(newState);

        //this.setState(prevstate => ({ openMenu: false}));
    };

}

export default TableDb;