import React from 'react';

import TableDb from './../table/tableDb';
import { LogicOperator } from '../../util/db/SqlQueryBuilder';

import Find from './find';
import Form from './form';
import CustomizedMenus from './../table/menu'

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Config from './../config/config'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DB from './../../util/db/db';
//-----------------------------------------------------

class Subject extends TableDb {
    constructor(props) {
        super(props);

        this.title = "Zoznam osôb";
        if(props.title !== undefined) this.state.title = props.title;

        this.dbTable = "subject";        

        this.state.columns = this.getColumns(props.ui);
        this.state.visibleColumns = this.getVisibleColumns(props.ui);

        this.state.isMobile = false;
        this.state.typ = props.typ;
        this.state.ui = props.ui;

        this.state.subject_id = props.subject_id;
        this.state.parent_id = props.parent_id;

        if(props.isMobile !== undefined)
        {
            if(props.isMobile) 
            {
                this.state.visibleColumns = ["actions", "meno_priezvisko"];
                this.state.isMobile = true;
            }
        }

        this.renamedColumns = {
          id: "id",
          ico: "ičo",
          typ: "právna forma",
          actions: "",
          meno_priezvisko: "meno",
          cop: "obč. preukaz",
          datum_narodenia: "dátum narodenia",
          created: "vytvorené",
          archiv: "archív"
        };
    }

    getColumns(ui)
    {
        if(ui === "SubjectCase")
        {
            return ["id", "actions", "meno_priezvisko", "typ", "ico", "created", "archiv"];
        }
        else if(ui === "SubjectEmployees")
        {
            return ["id", "actions", "meno_priezvisko", "cop", "datum_narodenia", "created", "archiv"];
        }

        //default
        return ["id", "actions", "meno_priezvisko", "typ", "ico", "created", "archiv"];
    }

    getVisibleColumns(ui)
    {
        if(ui === "SubjectCase")
        {
            return ["actions", "meno_priezvisko", "typ", "ico", "created"];
        }
        else if(ui === "SubjectEmployees")
        {
            return ["id", "actions", "meno_priezvisko", "cop", "datum_narodenia", "created"];
        }

        //default
        return ["actions", "meno_priezvisko", "typ", "ico", "created"];
    }

    componentWillReceiveProps (nextProps) {

        //iba ak subject_id, alebo parent_id bolo zmenene
        let setStateFlag = false;
        if(nextProps["subject_id"] !== undefined) setStateFlag = true;
        if(nextProps["parent_id"] !== undefined) setStateFlag = true;

        if(setStateFlag)
        {
            if(nextProps["subject_id"] === this.state.subject_id && nextProps["parent_id"] === this.state.parent_id) setStateFlag = false;
        }

        if(nextProps["ui"] !== this.state.ui) setStateFlag = true;
        if(nextProps["isMobile"] !== this.state.isMobile) setStateFlag = true;
        if(this.state.status !== TableDb.TABLE_STATUS.SHOWTABLE) setStateFlag = true;

        if(!setStateFlag) return;

        let newState = {...nextProps}
        newState["columns"] = this.getColumns(nextProps.ui);
        newState["status"] = TableDb.TABLE_STATUS.SHOWTABLE;
        newState["visibleColumns"] = this.getVisibleColumns(nextProps.ui);

        this.setState(
            newState,
            this.showTable
        );
    }

    getTableName(withView = false)
    {
        if(withView) return "view_subject";
        return this.dbTable;
    }

    getDefaultQueryBuilder()
    {

        let sqlQueryBuilder = super.getDefaultQueryBuilder();
        if(this.state.typ !== undefined)
        {
            if(this.state.typ === "PO|SZCO")
            {
                let subConditions = [];
                subConditions.push("`typ` = :typ1");
                subConditions.push("`typ` = :typ2");
                sqlQueryBuilder.where( "(" + subConditions.join(" || ") + ")", LogicOperator.And);
                
                sqlQueryBuilder.setParameter("typ1", "PO");
                sqlQueryBuilder.setParameter("typ2", "FO - podnikatel");

                //sqlQueryBuilder.where("`typ` = :typ", LogicOperator.And);
                //sqlQueryBuilder.setParameter("typ", this.props.typ);
            }
            else if(this.state.typ === "FO")
            {
                sqlQueryBuilder.where("`typ` = :typ", LogicOperator.And);
                sqlQueryBuilder.setParameter("typ", "FO");
            }

        }

        //moji klienti prav osoby - nesmie byt v case
        if(this.state.ui === "SubjectLegal")
        {
            sqlQueryBuilder.where("id not in (select subject_id from subject_case where archiv = false)", LogicOperator.And);
            sqlQueryBuilder.where("id not in (select subject_assigned_id from subject_assigned)", LogicOperator.And);
        }

        //moje spolocnosti, musi byt case
        if(this.state.ui === "SubjectCase")
        {
            sqlQueryBuilder.where("id in (select subject_id from subject_case where archiv = false)", LogicOperator.And);
        }

        //moje prevadzky, musi byt case, a musi byt priradena k parent_id
        if(this.state.ui === "SubjectCaseAndAssigned")
        {
            //sqlQueryBuilder.where("id in (select subject_id from subject_case where archiv = false)", LogicOperator.And);

            sqlQueryBuilder.where("id in (select subject_assigned_id from subject_assigned where `subject_id` = :subject_id AND role = 'pobocka')", LogicOperator.And);
            sqlQueryBuilder.setParameter("subject_id", this.state.parent_id);

            //this.state.subject_id;
            //this.state.parent_id;
            //subject_id , parent_id
        }

        //iba data vygenerovanie cez businessmanager
        sqlQueryBuilder.where("`zdroj` = :zdroj", LogicOperator.And);
        sqlQueryBuilder.setParameter("zdroj", 9);

        return sqlQueryBuilder;
    }

    buildFindSqlQuery(findDialogParams)
    {

        let sqlQueryBuilder = super.buildFindSqlQuery(findDialogParams);
        if(sqlQueryBuilder == null) return;

        if("meno_priezvisko" in findDialogParams)
        {
          if(typeof findDialogParams.meno_priezvisko === "string")
          {
            sqlQueryBuilder.where("`meno_priezvisko` LIKE :meno_priezvisko", LogicOperator.And);
            sqlQueryBuilder.setParameter("meno_priezvisko", "%" + findDialogParams["meno_priezvisko"] + "%");
          }
        }

        if("ico" in findDialogParams)
        {
          if(typeof findDialogParams.ico === "string")
          {
            sqlQueryBuilder.where("`ico` LIKE :ico", LogicOperator.And);
            sqlQueryBuilder.setParameter("ico", "%" + findDialogParams["ico"] + "%");
          }
        }

        this.showTable(sqlQueryBuilder);
    }

    menuClicked(menu)
    {
        //process default menu
        if(menu === "delete")
        {
            //alert(this.id + "-" + Config.subject.subject_id);

            if(this.id == Config.subject.subject_id)
            {
                this.menuClosed();
                this.showAlertDialog("mazanie", "Nie je možné zmazať hlavnú firmu");
                return;
            }

            if(this.selectedRows.length === 0)
            {
                this.menuClosed();
                this.showAlertDialog("Mazanie", "Nie sú označené žiadne riadky");
                return;
            }

            var r = window.confirm("Naozaj chcete zmazať?");
            if (r === false) return;

            let db = new DB();
            db.dbStartTransaction();
            
            //SubjectCaseAndAssigned - odstranit z subject_assigned, a zmazat subject
            if(this.state.ui === "SubjectCaseAndAssigned")
            {
                let conditions = {"subject_assigned_id": this.id};
                db.dbDelete("subject_assigned", conditions);
            }

            //SubjectCase - odstranit z subject_case, a zmazat subject
            if(this.state.ui === "SubjectCase")
            {
                let conditions = {"subject_id": this.id};
                db.dbDelete("subject_case", conditions);
            }
            
            //default - odstranit z subject
            let conditions = {"id": this.id};
            db.dbDelete(this.getTableName(false), conditions);
            db.dbCommitTransaction();
    
            let index = this.index;

            db.exec().then(
              result => {
                this.deleteRow(index);
              },
              error => {
                console.log(error);
                this.showAlertDialog("Chyba", error);
              }
            );

            return;

        }

        let processed = super.menuClicked(menu);
        if(processed) return;
        
        //alert(menu);
    }

    renderFindDialog()
    {
        //detect mobile
        let isFullScreen = false;
        if(this.props.isMobile !== undefined)
        {
            if(this.props.isMobile)
            {
                isFullScreen = true;
            }
        }

        //isFullScreen = true;//test
        return (
            <Find
                fullScreen={isFullScreen}
                onConfirm ={this.buildFindSqlQuery.bind(this)}
            />
        )
    }

    saveForm()
    {
        //? move here from form?
    }

    //add or edit
    renderForm()
    {
        let data = undefined;
        if(this.state.data == null);
        else data = this.state.data[this.index];

        //getIndex
        
        let status = TableDb.TABLE_STATUS.EDIT;
        if(data === undefined) 
        {
            data = {};
            status = TableDb.TABLE_STATUS.ADD;
        }

        return (
            <Form
                handleBack={this.handleBack.bind(this)}
                data = {data}
                status = {status}
                parent_id = {this.state.parent_id}
                ui = {this.state.ui}
            />
        )
    }

    renderUiForm()
    {
        return this.renderForm();
    }

    renderUiTable()
    {
        return(
            <Card>
                <CardHeader color="success">

                    <Grid container justify="space-between">  

                        <Typography inline variant="body1" align="left">

                            <Tooltip title="zobraz menu" arrow>
                                <Button
                                    ref={this.anchorMenuRef}
                                    aria-controls={this.state.openMenu ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleToggleMenu.bind(this)}
                                    startIcon={<MoreVertIcon />}
                                >
                                {this.state.title}
                            </Button>
                            </Tooltip>
                            {this.renderCancelFilterIcon()}

                            <IconButton onClick={() => this.menuClicked("find")} aria-label="search" color="inherit">
                                <SearchIcon />
                            </IconButton>

                        </Typography>

                        <Typography inline variant="body1" align="right">
                            <Tooltip title="vytvor klienta" arrow>
                                <IconButton onClick={() => this.menuClicked("add")} aria-label="search" color="inherit">
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Typography>

                    </Grid>

                    </CardHeader>
            <CardBody>
            <div>
                {this.renderTable()}
            </div>
            </CardBody>
        </Card>
        )
    }

    renderMenu()
    {
        return (
            <CustomizedMenus
            anchorref={this.anchorMenuRef.current}
            menuClicked = {this.menuClicked.bind(this)}
            menuClosed = {this.menuClosed.bind(this)}
            />
        );
    }
}

export default Subject;